import { UserGroupTypeJsonldUserGroupTypeWrite } from '@zucommunications/gsk-docshare-web-api'
import { UserGroupType } from '@/domain/userGroupType'

export const UserGroupTypeMapper = ({
  deserialize: (json): UserGroupType => {
    return <UserGroupType>{
      id: Number(json['@id'].replace('/api/user_group_types/', '')),
      title: json.title
    }
  },
  serializeIri: (groupType: UserGroupType): string => {
    return `/api/user_group_types/${groupType.id}`
  },
  serializePatch: (obj: UserGroupType): UserGroupTypeJsonldUserGroupTypeWrite => {
    return {
      title: obj.title
    }
  }
})
