import { AgendaGroupJsonldAgendaGroupRead, AgendaGroupJsonldAgendaGroupWrite, AgendaGroupAgendaGroupWrite } from '@zucommunications/gsk-docshare-web-api'
import { AgendaTemplate } from '@/domain/agendaTemplate'
import { AgendaTypeMapper } from '@/domain/agendaTypeMapper'
import { UserMapper } from '@/domain/userMapper'

export const AgendaTemplateMapper = ({
  deserialize: (json: AgendaGroupJsonldAgendaGroupRead): AgendaTemplate => {
    return <AgendaTemplate>{
      id: Number(json['@id'].replace('/api/agenda_groups/', '')),
      title: json.title,
      secretary: json.secretary?.length ? json.secretary.map(UserMapper.deserialize) : [],
      users: json.users?.length ? json.users.map(UserMapper.deserialize) : [],
      agendaType: json.agendaType ? AgendaTypeMapper.deserialize(json.agendaType) : undefined,
      documentFolderName: json.documentFolderName
    }
  },
  serializeIri: (obj: AgendaTemplate): string => {
    return `/api/agenda_groups/${obj.id}`
  },
  serializePost: (obj: AgendaTemplate): AgendaGroupJsonldAgendaGroupWrite => {
    return {
      title: obj.title,
      agendaType: obj.agendaType ? AgendaTypeMapper.serializeIri(obj.agendaType) : undefined,
      users: obj.users?.length ? obj.users.map(UserMapper.serializeIri) : undefined,
      secretary: obj.secretary ? obj.secretary.map(UserMapper.serializeIri) : undefined,
      documentFolderName: obj.documentFolderName
    }
  },
  serializePatch: (obj: AgendaTemplate): AgendaGroupAgendaGroupWrite => {
    return {
      title: obj.title,
      agendaType: obj.agendaType ? AgendaTypeMapper.serializeIri(obj.agendaType) : undefined,
      users: obj.users?.length ? obj.users.map(UserMapper.serializeIri) : undefined,
      secretary: obj.secretary ? obj.secretary.map(UserMapper.serializeIri) : undefined,
      documentFolderName: obj.documentFolderName
    }
  }
})
