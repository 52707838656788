import { UserGroupUserGroupWrite } from '@zucommunications/gsk-docshare-web-api'
import { UserGroupTypeMapper } from '@/domain/userGroupTypeMapper'
import { UserGroup } from '@/domain/userGroup'

export const UserGroupMapper = ({
  deserialize: (json): UserGroup => {
    return <UserGroup>{
      id: Number(json['@id'].replace('/api/user_groups/', '')),
      title: json.title,
      formattedTitle: json.title + (json.isActive === true ? '' : ' (inactive)'),
      titleAbbreviation: json.acronym,
      type: json.userGroupType ? UserGroupTypeMapper.deserialize(json.userGroupType) : undefined,
      isActive: json.isActive
    }
  },
  serializeIri: (group: UserGroup): string => {
    return `/api/user_groups/${group.id}`
  },
  serializePatch: (group: UserGroup): UserGroupUserGroupWrite => {
    return {
      title: group.title,
      acronym: group.titleAbbreviation,
      isActive: group.isActive,
      userGroupType: group.type ? UserGroupTypeMapper.serializeIri(group.type) : undefined
    }
  }
})
