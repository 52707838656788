import { UserMapper } from '@/domain/userMapper'
import { WorkflowGroup, WorkflowStep } from '@/domain/workflowGroup'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import { UserGroupTypeMapper } from '@/domain/userGroupTypeMapper'
import { WorkflowGroupJsonldWorkflowGroupWrite, WorkflowStepsDocumentPatch } from '@zucommunications/gsk-docshare-web-api'

export const WorkflowGroupMapper = ({
  deserializeForTemplateGroup: (json): WorkflowGroup => {
    return <WorkflowGroup>{
      id: Number(json['@id'].replace('/api/workflow_template_workflow_groups/', '')),
      title: json.workflowGroup?.title,
      position: json.position,
      users: json.WorkflowTemplateUserInclusion.map(x => UserMapper.deserialize(x))
    }
  },
  deserialize: (json): WorkflowGroup => {
    return <WorkflowGroup>{
      id: Number(json['@id'].replace('/api/workflow_groups/', '')),
      title: json.title,
      users: json.users.map(x => UserMapper.deserialize(x)),
      userGroup: json.userGroup ? UserGroupMapper.deserialize(json.userGroup) : undefined,
      userGroupType: json.userGroupType ? UserGroupTypeMapper.deserialize(json.userGroupType) : undefined,
      workflowType: json.workflowType,
      critical: json.critical
    }
  },
  serializeIri: (workflowGroup: WorkflowGroup): string => {
    return `/api/workflow_groups/${workflowGroup.id}`
  },
  serialize: (workflowGroup: WorkflowGroup): WorkflowGroupJsonldWorkflowGroupWrite => {
    return {
      title: workflowGroup.title,
      users: workflowGroup?.users?.map(x => UserMapper.serializeIri(x)),
      userGroup: workflowGroup.userGroup ? UserGroupMapper.serializeIri(workflowGroup.userGroup) : undefined,
      userGroupType: workflowGroup.userGroupType ? UserGroupTypeMapper.serializeIri(workflowGroup.userGroupType) : undefined,
      workflowType: workflowGroup.workflowType,
      critical: workflowGroup.critical
    }
  }
})

export const WorkflowStepMapper = ({
  deserialize: (json): WorkflowStep => {
    return <WorkflowStep>{
      id: Number(json['@id'].replace('/api/workflow_steps/', '')),
      user: json.user ? UserMapper.deserialize(json.user) : undefined,
      approved: json.approved,
      dateApproved: json.dateApproved ? new Date(json.dateApproved) : undefined,
      hasComment: json.hasComment,
      position: json.position
    }
  },
  serialize: (obj: WorkflowStep): WorkflowStepsDocumentPatch => {
    return {
      user: obj.user ? UserMapper.serializeIri(obj.user) : undefined,
      approved: obj.approved,
      position: obj.position
    }
  }
})
