import { AgendaType, AgendaTypeSection } from '@/domain/agendaType'
import { AgendaTypeJsonldAgendaTypeWrite, AgendaTypeAgendaTypeWrite } from '@zucommunications/gsk-docshare-web-api'
import {
  AgendaTypeAgendaTypeWritePackageTypeEnum,
  AgendaTypeJsonldAgendaTypeWritePackageTypeEnum
} from '@zucommunications/gsk-docshare-web-api/api'

export const AgendaTypeSectionMapper = ({
  deserialize: (json): AgendaTypeSection => {
    return <AgendaTypeSection>{
      id: Number(json['@id'].replace('/api/agenda_type_sections/', '')),
      title: json.title,
      position: json.position
    }
  }
})

export const AgendaTypeMapper = ({
  deserialize: (json): AgendaType => {
    return <AgendaType>{
      id: Number(json['@id'].replace('/api/agenda_types/', '')),
      title: json.title,
      packageType: json.packageType,
      sections: json.agendaTypeSections?.length ? json.agendaTypeSections.map(AgendaTypeSectionMapper.deserialize) : []
    }
  },
  serializeIri: (obj: AgendaType): string => {
    return `/api/agenda_types/${obj.id}`
  },
  serializePost: (obj: AgendaType): AgendaTypeJsonldAgendaTypeWrite => {
    return {
      title: obj.title || '',
      packageType: obj.packageType !== undefined ? obj.packageType as AgendaTypeJsonldAgendaTypeWritePackageTypeEnum : undefined,
      agendaTypeSections: obj.sections?.length ? obj.sections.map((value, index) => {
        return {
          position: index + 1,
          title: value.title
        }
      }) : undefined
    }
  },
  serializePatch: (obj: AgendaType): AgendaTypeAgendaTypeWrite => {
    return {
      title: obj.title || '',
      packageType: obj.packageType !== undefined ? obj.packageType as AgendaTypeAgendaTypeWritePackageTypeEnum : undefined,
      agendaTypeSections: obj.sections?.length ? obj.sections.map((value, index) => {
        return {
          position: index + 1,
          title: value.title
        }
      }) : undefined
    }
  }
})
