import { UserGroupMapper } from '@/domain/userGroupMapper'
import { UserUserGroupJsonldUserUserGroupWrite, UserUserGroupUserUserGroupPatch } from '@zucommunications/gsk-docshare-web-api'
import { UserId } from '@/domain/user'
import { GroupMembership } from '@/domain/groupMembership'

export const GroupMembershipMapper = ({
  deserialize: (json: any, user: UserId | undefined): [GroupMembership] => {
    return json.UserUserGroup.map(x => {
      const group = UserGroupMapper.deserialize(x.userGroup)
      return <GroupMembership>{
        id: x['@id'].replace('/api/user_user_groups/', ''),
        title: x.title,
        user,
        group,
        permissions: {
          isAdmin: x.admin,
          isHighRanking: x.highRanking,
          canAccessAllDocuments: x.allDocuments
        }
      }
    })
  },
  deserializePatch: (json: any): GroupMembership => {
    return <GroupMembership>{
      id: json['@id'].replace('/api/user_user_groups/', ''),
      title: json.title,
      permissions: {
        isAdmin: json.admin,
        isHighRanking: json.highRanking,
        canAccessAllDocuments: json.allDocuments
      }
    }
  },
  serializePatch: (groupMembership: GroupMembership): UserUserGroupUserUserGroupPatch => {
    return {
      title: groupMembership.title,
      allDocuments: groupMembership.permissions.canAccessAllDocuments,
      highRanking: groupMembership.permissions.isHighRanking,
      admin: groupMembership.permissions.isAdmin
    }
  },
  serializePost: (groupMembership: GroupMembership): UserUserGroupJsonldUserUserGroupWrite => {
    return {
      user: `/api/users/${groupMembership.user?.id}`,
      userGroup: `/api/user_groups/${groupMembership.group?.id}`,
      title: groupMembership.title,
      allDocuments: groupMembership.permissions.canAccessAllDocuments,
      highRanking: groupMembership.permissions.isHighRanking,
      admin: groupMembership.permissions.isAdmin
    }
  }
})
